.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f4;
  padding: 1em;
  box-sizing: border-box;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header h1 {
  font-size: 3em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #282c34;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  line-height: 1.2;
  position: relative;
}

.App-header h1::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 3px;
  background-image: linear-gradient(to right, #ff6f61, #4286f4);
}


.App-form {
  max-width: 600px;
  margin: 0 auto;
  background-color: white;
  padding: 2em;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.App-form label {
  display: block;
  margin-bottom: 1em;
}

.App-form input,
.App-form textarea,
.App-form select {
  width: 100%;
  padding: 0.5em;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 1em;
  margin-bottom: 1em;
  box-sizing: border-box;
}

.App-form button {
  padding: 0.5em 2em;
  border-radius: 4px;
  border: none;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1.2em;
  width: 100%;
  margin-bottom: 1em;
  box-sizing: border-box;
}

.App-form button.start {
  background-color: #28a745; /* Green color */
}

.App-form button.start:hover {
  opacity: 0.8;
}

.App-form button.stop {
  background-color: #dc3545; /* Red color */
}

.App-form button.stop:hover {
  opacity: 0.8;
}

.App-form button.reset {
  background-color: #007bff; /* Blue color */
}

.App-form button.reset:hover {
  opacity: 0.8;
}


.App-link {
  color: #61dafb;
  margin-top: 2em;
}

/* Responsive Styles */
@media only screen and (max-width: 480px) {
  .App-header h1 {
    font-size: 1.8em;
  }

  .App-form {
    padding: 1em;
    max-width: 100%;
  }

  .App-form input,
  .App-form textarea,
  .App-form select {
    font-size: 0.8em;
  }

  .App-form button {
    font-size: 1em;
  }
}

.field-spacing {
  margin-top: 1em;
}
/* Your existing CSS here */

.App-form .edit-button {
  background-color: #f4a442; /* Change color as you like */
  color: white;
}

.App-form .edit-button:hover {
  opacity: 0.8;
}

.App-form .submit-edit-button {
  background-color: #8b61ff; /* Change color as you like */
  color: white;
}

.App-form .submit-edit-button:hover {
  opacity: 0.8;
}
